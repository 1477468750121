import React from 'react'
import styled from 'styled-components'
import Button from './button'
import { device } from '../utils/devices'

const Wrapper = styled.div`
  -webkit-box-align: center;
  text-align: center;
  align-items: center;
  padding: 3rem 0px;
  color: black;
  font-family: ${props => props.theme.type.body};

  @media ${device.laptop}{
    display: grid;
    grid-template-columns: 2fr 4fr 4fr 1fr;
    grid-gap: 3vw 3vw;
    text-align: left;
  }

  h1 {
    text-transform: uppercase;
    font-size: 48px;
    margin: 0 0 1rem;

    @media ${device.laptop}{
      margin: 0;
    }
  }

  h2 {
    font-size: 36px;
    margin: 0 0 1rem;

    @media ${device.laptop}{
      margin: 0;
    }
  }

  h3 {
    font-size: 36px;
    margin: 0 0 2rem;

    @media ${device.laptop}{
      margin: 0;
    }
  }
`;

const Event = ({ date, time, location, venue, link }) => (
  <Wrapper>
    <h1>{date}</h1>
    <h2>{location}</h2>
    <h3>{venue}</h3>
    <a href={link} target='_blank' rel='noopener noreferrer'><Button>Tickets</Button></a>
  </Wrapper>
);

export default Event;
