import React from 'react'
import styled from 'styled-components'
import WaterWave from 'react-water-wave'

const Wrapper = styled.div`
  width: 100vw;
  height: 56.25vw;
  margin: 4rem auto;
`;

const Image = ({ image }) => (
  <Wrapper>
    {typeof window !== 'undefined' && WaterWave &&
    <WaterWave
      style={{ width: '100%', height: '100%', backgroundSize: 'cover' }}
      imageUrl={image}
      dropRadius={40}
      perturbance={0.02}
      resolution={480}
    />
    }
  </Wrapper>
);

export default Image;
