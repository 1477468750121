import React from 'react'
import styled from 'styled-components';

const Foot = styled.footer`
  text-align: center;
  font-size: 24px;
  margin: 8rem 0 4rem;
`;

const Footer = (props) => (
  <Foot>© {new Date().getFullYear()} Million Swag Surf</Foot>
);

export default Footer;
