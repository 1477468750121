import React from 'react'
import InstagramEmbed from 'react-instagram-embed'
import styled from 'styled-components'
import { device } from '../utils/devices'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  div {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    iframe {
      width: 90vw !important;

      @media ${device.laptop}{
        width: 100% !important;
      }
    }
  }
`;

const Instagram = ({ url }) => (
  <Wrapper>
    <InstagramEmbed
      url={url}
      maxWidth={800}
      hideCaption={true}
      injectScript
    />
  </Wrapper>
);

export default Instagram;
