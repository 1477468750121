import React, { Component } from 'react'
import Button from './button'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: calc(100vw - 4rem);
  padding: 2rem;
  max-width: 500px;
  margin: 4rem auto 2rem;
  background: white;

  h2 {
    font-size: 48px;
    text-align: center;
    font-weight: 800;
    color: ${props => props.theme.color.primary};
  }
`;

const Form = styled.form`

  input, textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0,0,0,0.23);
    background: white;
    border-radius: 4px;
    padding: 1rem;
    font-size: 16px;
  }

  button {
    width: 100%;
    box-sizing: border-box;
  }
`;

const encode = (data) => {
   return Object.keys(data)
       .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
       .join("&");
 }

class contact extends Component {

  state = {
    name: '',
    email: '',
    message: '',
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(() => {
        this.setState({
          name: '',
          email: '',
          message: ''
        })
      })
      .catch(error => alert(error));

    e.preventDefault();
  };

  handleChange = e => {
    const { value, id } = e.target;
    console.log(value)
    this.setState({ [id]: value })
  }

  render() {

    const { name, email, message } = this.state;

    return (
      <Wrapper>
        <h2>Get in Touch</h2>
        <Form name='contact' method='post' data-netlify="true" onSubmit={this.handleSubmit} data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <input id='name' onChange={this.handleChange} value={name} placeholder='Name' type="text" name="name"/>
          </p>
          <p>
            <input id='email' onChange={this.handleChange} value={email} placeholder='Email' type="email" name="email"/>
          </p>
          <p>
            <textarea id='message' onChange={this.handleChange} value={message} placeholder='Enter your message'  name="message"></textarea>
          </p>
          <p>
            <Button type="submit" disabled={name === '' && email === '', message === ''}>Send</Button>
          </p>
        </Form>
      </Wrapper>
    );
  }

}

export default contact;
