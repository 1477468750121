import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from '../components/Text'
import Image from '../components/image'
import image from '../images/image-1.jpg'
import image2 from '../images/image-2.jpg'
import styled from 'styled-components'
import arrow from '../images/arrow.svg'
import buzzfeed from '../images/buzzfeed.png'
import Instagram from '../components/Instagram'
import { device } from '../utils/devices'
import Video from '../components/Video'
import Social from '../components/Social'
import EventList from '../components/EventList'
import Button from '../components/button'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Contact from '../components/contact'

const Arrow = styled.div`
  display: flex;
  justify-content: center;
`;

const Media = styled.div`
  width: 90vw;
  margin: 0 auto;

  img {
    margin-top: 2rem;
  }

  @media ${device.laptop}{
    width: 70vw;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

    iframe, video {
      margin: 0 !important;
    }

    .article {
      height: 100%;
      overflow: hidden;
    }

    img {
      width: 100%;
      border-radius: 4px;
      max-width: 685px;
      transition: 0.2s all ease-in-out;

      &:hover {
        transform: translate(0, -2px);
      }
    }
  }
`;

const Notice = styled.div`
  text-align: center;
  width: 90vw;
  margin: 0 auto 4rem;
  background: white;
  padding: 4rem 2rem;
  max-width: calc(1000px + 8rem);
  font-size: 18px;
  color: rgb(54,54,54);
  border-radius: 4px;

  strong {
    font-weight: 600;
  }

  @media ${device.laptop}{
    font-size: 24px;
  }

  p {
    line-height: 1.6em;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <EventList />
    <Text title='What is the Million Swag Surf?'>
      We're setting a world record for the biggest swag surf ever.
    </Text>
    <Video />
    <Text title='Wow. What?'>
      We want to raise awareness for blood cancer/bone marrow donations. Unlike other forms of cancer, blood cancer has higher treatment rates if you can find a bone marrow donor. But you have to match with someone of similar ethnic background.
    </Text>
    <Text>
      Historically, communities of color are underrepresented in the bone marrow donation registry. This makes it really, really hard for people of color to find matches. This also makes treatable blood cancers like Leukemia and Lymphoma, and other blood diseases like Sickle Cell fatal. But if you find a match, the outcomes are much better. The challenge is a science problem, but it’s also a community awareness problem, which is why you can help make a difference; we need you.
    </Text>
    <Image alt='image 1' image={image} />
    <Text title="Okay, okay. I'm down. How do we do this?">
    The Million Swag Surf will take place at large-scale events where communities of color congregate (think homecomings, concerts, music festivals, etc.). If they build it, we will come. At a designated time and place during the event, attendees will participate in a record-breaking Swag Surf.
    </Text>
    <Text>
      Similar to how the Ice Bucket Challenge raised awareness for ALS, Million Swag Surf isn’t just about a dance, it’s about creating authentic community engagement from the ground up (literally), for causes that matter. We want to engage communities, brands, and institutions around blood cancer and bone marrow donation to educate, inspire, and save lives in the process.
    </Text>
    <Notice>
      <p>Black people make up only 4% of the <strong>Be The Match</strong> registry. Only we can save us.<br/> Help fight blood cancer and save Black lives by joining the registry.</p><br/>
      <OutboundLink href="http://www.timetobethematch.org" target="_blank" rel="noopener noreferrer"><Button>Join The Be The Match Registry</Button></OutboundLink>
    </Notice>

    <Image alt='image 2' image={image2} />
    <Text title='Wait.' />
    <Text title='Did you really swag surf at The White House?'>
      We sure did.
    </Text>
    <Arrow><img alt='arrow' src={arrow} /></Arrow>
    <Media>
      <Instagram url='https://www.instagram.com/p/BLPqGYPg349/' />
      <div className='article'><a target='_blank' rel="noopener noreferrer" href="https://www.buzzfeednews.com/article/juliareinstein/people-swag-surfed-on-the-white-house-lawn-for-an-important"><img alt='Buzzfeed article' src={buzzfeed} /></a></div>
    </Media>
    <Contact />
    <Social />
  </Layout>
)

export default IndexPage
