import React from 'react'
import styled from 'styled-components'
import { FaTwitter, FaInstagram } from 'react-icons/fa'

const List = styled.ul`
  display: flex;
  margin: 6rem 0;
  justify-content: center;
  list-style: none;

  a {
    color: white;
  }

  li {
    margin-right: 1rem;

    &:last-of-type {
      margin: 0;
    }
  }
`;

const Social = (props) => (
  <List>
    <li><a rel="noopener noreferrer" target="_blank" href="https://twitter.com/millionswagsurf?lang=en"><FaTwitter size={48} /></a></li>
    <li><a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/millionswagsurf/"><FaInstagram size={48} /></a></li>
  </List>
);

export default Social;
