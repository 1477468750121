import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components'
import logo from '../images/logo.svg'
import { device } from '../utils/devices'
import bg from '../images/waves.svg'

const Head = styled.header`
  padding: 4rem 4rem;
  display: flex;
  justify-content: center;
  position: relative;

  @media ${device.laptop}{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 20vw;
    min-width: 200px;
    position: relative;
    z-index: 2;
  }
`;

const Bg = styled.div`
  background: url(${bg});
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 130%;
  background-position: center center;
  opacity: 0.7;
`;

const Header = ({ siteTitle }) => (
  <Head>
    <Bg />
    <img src={logo} alt='Logo' />
  </Head>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
