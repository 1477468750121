import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from './footer'
import "./layout.css"
import styled, { ThemeProvider } from 'styled-components'

const theme = {
  color: {
    text: 'rgb(255,255,255,)',
    primary: 'rgb(0, 177, 222)',
  },
  type: {
    body: `'Avenir Next', 'Avenir', 'Nunito Sans', Helvetica, sans-serif`
  }
}

const Main = styled.main`
  margin: 0 auto 4rem;
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Main>{children}</Main>
          <Footer />
          </>
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
