import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device } from '../utils/devices'

const Block = styled.div`
  text-align: center;
  width: 90vw;
  margin: 0 auto 4rem;
  max-width: 1000px;
`;

const Title = styled.h2`
  font-family: ${props => props.theme.type.body};
  font-weight: 800;
  font-size: 24px;

  @media ${device.laptop}{
    font-size: 36px;
  }
`;

const TextBlock = styled.div`
  font-size: 18px;

  @media ${device.laptop}{
    font-size: 24px;
  }

  p {
    line-height: 1.6em;
  }
`;

const Text = ({ title, children }) => (
  <Block>
    <Title>{title}</Title>
    <TextBlock><p>{children}</p></TextBlock>
  </Block>
);

Text.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default Text;
