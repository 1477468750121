import React from 'react'
import styled from 'styled-components'
import { device } from '../utils/devices'

const Wrapper = styled.button`
  background: ${props => props.light ? 'white'  : props.theme.color.primary };
  color: ${props => props.light ? props.theme.color.primary : 'white' };
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  border: 0;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  outline: 0;
  box-shadow: rgba(50, 50, 93, 0.11) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px 1px 3px;
  width: 100%;
  box-sizing: border-box;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer' };

  @media ${device.laptop}{
    width: auto;
  }

  &:hover {
    transform: translate(0, -2px);
  }
`;

const Button = ({ children, light, disabled }) => (
  <Wrapper light={light} disabled={disabled}>
    {children}
  </Wrapper>
);

export default Button;
