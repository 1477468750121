import React from 'react'
import styled from 'styled-components'
import Event from './event'

const Wrapper = styled.div`
  background: white;
  margin: 0 0 6rem;
`;

const List = styled.ul`
  width: 90vw;
  margin: 0 auto;
  padding: 0;
`;

const events = [
  {
    date: "Apr 27",
    time: "TBA",
    venue: "Something in the Water",
    link: "https://somethinginthewater.com",
    location: "Virginia Beach, VA"
  }
]

const EventList = (props) => (
  <Wrapper>
    <List>
      {events.map(event => (
        <Event
          date={event.date}
          time={event.time}
          link={event.link}
          location={event.location}
          venue={event.venue}
        />
      ))}
    </List>
  </Wrapper>
);

export default EventList;
