import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'

const Wrapper = styled.div`
  margin-bottom: 4rem;

  .video_player {
    width: 100vw !important;
    height: auto !important;
    padding-bottom: 56.25vw;
    position: relative;

    iframe, video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

const Video = (props) => (
  <Wrapper>
    <ReactPlayer
      controls
      className='video_player'
      url='https://vimeo.com/332122375'
    />
  </Wrapper>
);

export default Video
